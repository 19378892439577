var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"className":"animated"}},[_c('b-card',{staticClass:"p-0 m-0"},[_c('b-card-header',[_c('i',{staticClass:"icon-menu mr-1"}),_vm._v(" Cron Monitoring ")]),_c('b-card-body',[(_vm.isLoadingCron == true)?_c('b-row',_vm._l((3),function(n){return _c('b-col',{key:n,staticClass:"mb-4",attrs:{"sm":"4"}},[_c('div',{staticClass:"wrapper-item-cron"},[_c('div',{staticClass:"header-item-cron"},[_c('b-skeleton',{staticStyle:{"border-radius":"6px"},attrs:{"height":"44px","width":"44px"}}),_c('b-skeleton',{staticStyle:{"border-radius":"100px","margin":"unset"},attrs:{"height":"32px","width":"53px"}})],1),_c('div',{staticClass:"content-item-cron"},[_c('b-skeleton',{staticClass:"mt-5 mb-4",attrs:{"height":"22px","width":"200px"}}),_c('div',[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('b-skeleton',{attrs:{"height":"17.5px","width":"64px"}}),_c('b-skeleton',{attrs:{"height":"17.5px","width":"200px"}})],1),_c('div',{staticClass:"d-flex align-items-start justify-content-between my-2"},[_c('b-skeleton',{attrs:{"height":"17.5px","width":"100px"}}),_c('b-skeleton',{attrs:{"height":"17.5px","width":"160px"}})],1),_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('b-skeleton',{attrs:{"height":"17.5px","width":"37px"}}),_c('b-skeleton',{attrs:{"height":"17.5px","width":"100px"}})],1)])],1)])])}),1):_vm._e(),_c('b-row',_vm._l((_vm.data),function(item,index){return _c('b-col',{key:index,staticClass:"mb-4",attrs:{"sm":"4"}},[_c('div',{staticClass:"wrapper-item-cron"},[_c('div',{staticClass:"header-item-cron"},[_c('div',{class:[
                    'item-cron-icon',
                    _vm.getImageSrc(item.cron_title, 'title')
                  ]},[_c('img',{staticStyle:{"width":"24px","height":"24px"},attrs:{"src":_vm.getImageSrc(item.cron_title, 'img')}})]),_c('b-form-checkbox',{staticClass:"custom-toggle",attrs:{"name":"check-button","switch":""},on:{"change":function($event){return _vm.onSetActive(item.id, item.is_active)}},model:{value:(item.is_active),callback:function ($$v) {_vm.$set(item, "is_active", $$v)},expression:"item.is_active"}})],1),_c('div',{staticClass:"content-item-cron"},[_c('h4',{staticClass:"mt-5 mb-4"},[_vm._v(_vm._s(item.cron_title))]),_c('div',[_c('div',{staticClass:"d-flex align-items-start justify-content-between"},[_c('p',{staticClass:"m-0"},[_vm._v("Cron name")]),_c('p',{staticClass:"m-0 text-right",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.cron_name)+" ")])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between my-2"},[_c('p',{staticClass:"m-0"},[_vm._v("Last running time")]),_c('p',{staticClass:"m-0 text-right"},[_vm._v(_vm._s(item.last_active))])]),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"m-0"},[_vm._v("Status")]),_c('p',{staticClass:"m-0",class:{
                        'text-success': item.is_running,
                        'text-danger': !item.is_running
                      }},[_vm._v(" "+_vm._s(item.is_running ? "running" : "completed")+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.detail.length > 0),expression:"item.detail.length > 0"}],staticClass:"wrapper-collapse mt-4"},[_c('b-collapse',{staticClass:"mt-4 mb-2",attrs:{"visible":_vm.handleExpand(item.cron_title, 'value'),"id":'collapse-' + index}},[_c('div',{staticClass:"py-3",staticStyle:{"border-top":"1px solid #E4E7EA"}},_vm._l((item.detail),function(itm,idx){return _c('div',{key:idx,class:[
                          'd-flex',
                          'align-items-start',
                          'justify-content-between',
                          index > 0 ? 'mt-2' : ''
                        ]},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(itm.title))]),_c('p',{staticClass:"m-0 text-right"},[_vm._v(_vm._s(itm.value))])])}),0)]),_c('p',{staticClass:"text-center",staticStyle:{"color":"#20A8D8","cursor":"pointer"},attrs:{"aria-expanded":_vm.handleExpand(item.cron_title, 'value')
                        ? 'true'
                        : 'false',"aria-controls":'collapse-' + index},on:{"click":function($event){return _vm.handleExpand(item.cron_title, 'toggle')}}},[_vm._v(" "+_vm._s(_vm.handleExpand(item.cron_title, "value") ? "Close" : "See Detail")+" "),_c('i',{class:[
                        'ml-2',
                        'fa',
                        _vm.handleExpand(item.cron_title, 'value')
                          ? 'fa-angle-up'
                          : 'fa-angle-down'
                      ],attrs:{"aria-hidden":"true"}})])],1)])])])}),1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }