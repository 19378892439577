<template>
  <div>
    <div className="animated">
      <b-card class="p-0 m-0">
        <b-card-header>
          <i class="icon-menu mr-1"></i> Cron Monitoring
        </b-card-header>
        <b-card-body>
          <b-row v-if="isLoadingCron == true">
            <b-col sm="4" v-for="n in 3" :key="n" class="mb-4">
              <div class="wrapper-item-cron">
                <div class="header-item-cron">
                  <b-skeleton
                    height="44px"
                    width="44px"
                    style="border-radius: 6px;"
                  ></b-skeleton>
                  <b-skeleton
                    height="32px"
                    width="53px"
                    style="border-radius: 100px; margin: unset"
                  ></b-skeleton>
                </div>
                <div class="content-item-cron">
                  <b-skeleton
                    class="mt-5 mb-4"
                    height="22px"
                    width="200px"
                  ></b-skeleton>
                  <div>
                    <div
                      class="d-flex align-items-start justify-content-between"
                    >
                      <b-skeleton height="17.5px" width="64px"></b-skeleton>
                      <b-skeleton height="17.5px" width="200px"></b-skeleton>
                    </div>
                    <div
                      class="d-flex align-items-start justify-content-between my-2"
                    >
                      <b-skeleton height="17.5px" width="100px"></b-skeleton>
                      <b-skeleton height="17.5px" width="160px"></b-skeleton>
                    </div>
                    <div
                      class="d-flex align-items-start justify-content-between"
                    >
                      <b-skeleton height="17.5px" width="37px"></b-skeleton>
                      <b-skeleton height="17.5px" width="100px"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="(item, index) in data"
              :key="index"
              sm="4"
              class="mb-4"
            >
              <div class="wrapper-item-cron">
                <div class="header-item-cron">
                  <div
                    :class="[
                      'item-cron-icon',
                      getImageSrc(item.cron_title, 'title')
                    ]"
                  >
                    <img
                      :src="getImageSrc(item.cron_title, 'img')"
                      style="width: 24px; height: 24px;"
                    />
                  </div>
                  <b-form-checkbox
                    class="custom-toggle"
                    v-model="item.is_active"
                    name="check-button"
                    switch
                    @change="onSetActive(item.id, item.is_active)"
                  >
                  </b-form-checkbox>
                </div>
                <div class="content-item-cron">
                  <h4 class="mt-5 mb-4">{{ item.cron_title }}</h4>
                  <div>
                    <div
                      class="d-flex align-items-start justify-content-between"
                    >
                      <p class="m-0">Cron name</p>
                      <p class="m-0 text-right" style="max-width: 200px;">
                        {{ item.cron_name }}
                      </p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between my-2"
                    >
                      <p class="m-0">Last running time</p>
                      <p class="m-0 text-right">{{ item.last_active }}</p>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-between"
                    >
                      <p class="m-0">Status</p>
                      <p
                        :class="{
                          'text-success': item.is_running,
                          'text-danger': !item.is_running
                        }"
                        class="m-0"
                      >
                        {{ item.is_running ? "running" : "completed" }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="wrapper-collapse mt-4"
                    v-show="item.detail.length > 0"
                  >
                    <b-collapse
                      :visible="handleExpand(item.cron_title, 'value')"
                      :id="'collapse-' + index"
                      class="mt-4 mb-2"
                    >
                      <div class="py-3" style="border-top: 1px solid #E4E7EA;">
                        <div
                          :class="[
                            'd-flex',
                            'align-items-start',
                            'justify-content-between',
                            index > 0 ? 'mt-2' : ''
                          ]"
                          v-for="(itm, idx) in item.detail"
                          :key="idx"
                        >
                          <p class="m-0">{{ itm.title }}</p>
                          <p class="m-0 text-right">{{ itm.value }}</p>
                        </div>
                      </div>
                    </b-collapse>
                    <p
                      @click="handleExpand(item.cron_title, 'toggle')"
                      :aria-expanded="
                        handleExpand(item.cron_title, 'value')
                          ? 'true'
                          : 'false'
                      "
                      :aria-controls="'collapse-' + index"
                      class="text-center"
                      style="color: #20A8D8; cursor: pointer;"
                    >
                      {{
                        handleExpand(item.cron_title, "value")
                          ? "Close"
                          : "See Detail"
                      }}
                      <i
                        :class="[
                          'ml-2',
                          'fa',
                          handleExpand(item.cron_title, 'value')
                            ? 'fa-angle-up'
                            : 'fa-angle-down'
                        ]"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "cron-monitoring",
  components: {},
  data() {
    return {
      data: [],
      expand: {},
      errors: {
        code: "",
        status: "",
        message: ""
      },
      isLoading: false,
      isLoadingCron: true,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      }
    };
  },
  methods: {
    async onFetch() {
      await this.$http
        .get("cron-setting?page=1&per_page=10")
        .then(result => {
          this.isLoadingCron = false;
          const payload = result.data.data;
          this.data = payload;
          this.handleSetExpand(payload);
        })
        .catch(error => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.message = error.response.message;
            this.errors.status = error.response.data.meta.code;

            this.$swal
              .fire("Failed!", `${this.errors.message}`, "error")
              .then(() => {
                location.reload();
              });
          }
        });
    },
    async onSetActive(id, active) {
      this.isLoading = true;

      await this.$http
        .post(`cron-setting/set-active/${id}`, { active })
        .then(result => {
          this.isLoading = false;
          const res = result.data;

          if (res.meta.code == 200) this.onFetch();
        })
        .catch(error => {
          this.isLoading = false;
          const err = error?.response;
          if (err) {
            this.errors.code = err.status;
            this.errors.message = err.data.meta.message;
            this.errors.status = err.data.meta.code;

            if (this.errors.status === 404) {
              this.$swal
                .fire("Failed!", `${this.errors.message}`, "error")
                .then(() => {
                  location.reload();
                });
            }

            if (this.errors.status === 400) {
              this.$swal.fire("Failed!", this.errors.message, "error");
            }
          }
        });
    },
    getImageSrc(title, type) {
      const lowerTitle = title.toLowerCase();

      switch (lowerTitle) {
        case "check invoice":
          if (type === "img") {
            return require("@/assets/img/cron-check.svg");
          } else {
            return "check-invoice";
          }
        case "multiple send ich transaction":
          if (type === "img") {
            return require("@/assets/img/cron-transaction.svg");
          } else {
            return "multiple-transaction";
          }
        case "buy gold wallet check":
          if (type === "img") {
            return require("@/assets/img/cron-wallet.svg");
          } else {
            return "check-wallet";
          }
        default:
          break;
      }
    },
    handleExpand(title, type) {
      const lowerTitle = title
        .toLowerCase()
        .split(" ")
        .join("_");

      if (type === "toggle") {
        this.expand = {
          ...this.expand,
          [lowerTitle]: !this.expand[lowerTitle]
        };
      } else {
        return this.expand[lowerTitle];
      }
    },
    handleSetExpand(value) {
      let val = {};

      value.map(itm => {
        val = {
          ...val,
          [itm.cron_title
            .toLowerCase()
            .split(" ")
            .join("_")]: false
        };
      });

      this.expand = val;
    }
  },
  mounted() {
    this.onFetch();
  }
};
</script>

<style>
.wrapper-item-cron {
  padding: 24px;
  border: 1px solid #e4e7ea;
}

.header-item-cron {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.item-cron-icon {
  width: fit-content;
  height: fit-content;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-invoice {
  background-color: #20a8d8;
}
.check-wallet {
  background-color: #25b1bf;
}
.multiple-transaction {
  background-color: #7565f6;
}

.custom-toggle {
  margin: 0 20px 12px 0;
}

.custom-toggle .custom-control-label::before {
  width: 53px;
  height: 32px;
  border-radius: 100px;
}

.custom-toggle .custom-control-label::after {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0.36em;
  left: -2.4em;
  border-radius: 100px;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label::before {
  width: 53px;
  height: 32px;
  border-color: #30d158;
  background-color: #30d158;
}

.custom-toggle .custom-control-input:checked ~ .custom-control-label::after {
  top: 0.36em;
  left: -1.6em;
  width: 28px;
  height: 28px;
}
</style>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
